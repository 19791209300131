import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const Text = () => (

  <Container>
    <Row>
        <Col>
            <h1>freesoul.dev/</h1>
            <hr />
            <a href="https://work.freesoul.dev"><h3>/r+d</h3></a>
            <a href="https://art.freesoul.dev"><h3>/art</h3></a>
            <a href="https://read.cv/freesoul"><h3>read.cv</h3></a>
            <a href="https://www.are.na/freesoul"><h3>are.na</h3></a>
            {/* <a href="https://github.com/freesoul-dev"><h3>github</h3></a> */}
            <a href="https://www.youtube.com/@freesoul-dev"><h3>youtube</h3></a>
            {/* <a href="https://vimeo.com/freesouldev"><h3>vimeo</h3></a> */}
            {/* <a href="https://stream.freesoul.dev"><h3>twitch.tv</h3></a> */}
        </Col>
    </Row>
    <footer>
      <code>Copyright © 2024 Freesoul El Shabazz-Thompson, All Rights Reserved</code>
    </footer>
  </Container>

);

export default Text;
